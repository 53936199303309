import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import * as api from './loginAPI'

export interface LoginState {
  webId: string
  isLoggedIn: boolean
  status: 'idle' | 'loading' | 'failed'
}

const initialState: LoginState = {
  webId: '',
  isLoggedIn: false,
  status: 'loading',
}

export const login = createAsyncThunk('login/login', api.login)
export const init = createAsyncThunk('login/init', api.init)
export const logout = createAsyncThunk('login/logout', api.logout)

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.status = 'loading'
      })
      .addCase(login.fulfilled, state => {
        state.status = 'idle'
        state.isLoggedIn = true
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'failed'
        alert(action.error.message)
      })
      .addCase(init.pending, state => {
        state.status = 'loading'
      })
      .addCase(init.fulfilled, (state, action) => {
        state.status = 'idle'
        state.isLoggedIn = action.payload.isLoggedIn
        state.webId = action.payload?.webId ?? ''
      })
      .addCase(logout.fulfilled, state => {
        state.status = 'idle'
        state.isLoggedIn = false
        state.webId = ''
      })
  },
})

export const selectLogin = (state: RootState) => state.login

export default loginSlice.reducer
